<template>
  <!-- <van-swipe class="swipe-content"
             type="card"
             vertical>
    <van-swipe-item default="2"
                    v-for="item in testImages"
                    :key="item"
                    @click="toSubjectList(item)">
      <img :src="item">
    </van-swipe-item>
  </van-swipe> -->
  <div>
    <Collection :imgData="subjects" @toList="toSubjectList" />
  </div>
</template>

<script>
import { fetchTypes } from "@/network/API";
import { urlToBase64 } from "@/utils/tools";
import Collection from "@/components/Collection.vue";
export default {
  components: {
    Collection,
  },
  data() {
    return {
      subjects: [],
      currentImage: {},
    };
  },
  created() {
    this.getSubjectList();
  },
  methods: {
    getBase64(url, callback) {
      return new Promise((resolve, _) => {
        var Img = new Image(),
          dataURL = "";
        Img.src = url + "?v=" + Math.random();
        // Img.setAttribute("crossOrigin", 'Anonymous')
        Img.onload = function () {
          var canvas = document.createElement("canvas"),
            width = Img.width,
            height = Img.height;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(Img, 0, 0, width, height);
          dataURL = canvas.toDataURL("image/jpeg");
          resolve(dataURL);
          callback ? callback(dataURL) : null;
        };
      });
    },
    async getSubjectList() {
      let data = await fetchTypes();
      let list = [];
      data.forEach((item, index) => {
        if (item.is_poster == 1) {
          list.push(item);
        }
      });
      this.subjects = list;
    },
    toSubjectList(item) {
      this.$store.commit("showHeader", {
        showBar: true,
      });
      this.$router.push({
        path: "/list",
        query: {
          tid: item.type_id,
          name: item.type_name,
        },
      });
    },
  },
};
</script>

<style>
.swipe-content {
  height: 500rpx;
}

.swipe-content img {
  width: 80%;
  /* height: 500rpx; */
  display: block;
}
</style>
