import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
Vue.use(VueRouter);

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      showHeader: false,
      title: "",
      showTab: true,
    },
  },
  {
    path: "/list",
    name: "List",
    meta: {
      showHeader: true,
      showTab: false,
    },
    component: () => import("../views/List.vue"),
  },
  {
    path: "/detail/:id",
    name: "Detail",
    meta: {
      showHeader: true,
    },
    component: () => import("../views/Detail.vue"),
  },
  {
    path: "/ztlist",
    name: "ztlist",
    meta: {
      showHeader: true,
      title: "专题列表",
      showTab: true,
    },
    component: () => import("../views/ZTList.vue"),
  },
  {
    path: "/nlist",
    name: "NewsList",
    meta: {
      showHeader: true,
      showTab: false,
    },
    component: () => import("../views/NewsList.vue"),
  },
  {
    path: "/mine",
    name: "Mine",
    meta: {
      showTab: true,
    },
    component: () => import("../views/User.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;