import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import {
  fetchOptions
} from "@/network/API";


const state = {
  homeTitle: "",
  navTitle: "",
  pushed: false,
  showBar: true,
};

const getters = {
  getNavTitle: state => state.navTitle
};

const mutations = {
  updateTitle(state, playload) {
    // state.homeTitle = playload.homeTitle;
    // state.navTitle = playload.navTitle;
    state.pushed = playload.pushed;
  },
  updateNavTitle(state, playload) {
    state.navTitle = playload.navTitle
    if (playload.homeTitle != undefined) {
      state.homeTitle = playload.homeTitle
    }
  },
  showHeader(state, playload) {
    // state.showBar = playload.showBar;
  },
};

const actions = {
  async navTitleAction(store) {
    let titles = await fetchOptions({
      key: "applet",
    });
    var homeTitle = titles[0].home_title;
    var siteName = titles[0].site_name;
    document.title = siteName;
    store.commit('updateNavTitle', {
      navTitle: store.state.navTitle == "" ? homeTitle : store.state.navTitle,
      homeTitle: homeTitle,
    })

  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});