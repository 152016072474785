<template>
  <van-nav-bar
    :title="getTitle"
    left-text=""
    z-index="89999"
    fixed
    @click-left="goback()"
    safe-area-inset-top
    v-show="$store.state.showBar"
    :left-arrow="$store.state.pushed"
  />
</template>

<script>
import { NavBar } from "vant";
export default {
  components: {
    NavBar,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    getTitle() {
      return this.$router.name == "ztlist"
        ? this.$store.state.homeTitle
        : this.$store.state.navTitle;
    },
  },

  data() {
    return {
      pageTitle: this.$store.state.navTitle,
      homeTitle: "",
    };
  },
  created() {},
  methods: {
    goback() {
      this.$router.back(-1);
      // this.pageTitle = this.$store.state.homeTitle;

      // this.$store.commit("updateNavTitle", {
      //   navTitle: this.$store.state.homeTitle,
      // });
    },
  },
};
</script>

<style>
.van-nav-bar .van-icon {
  color: black;
}
.van-nav-bar--fixed {
  position: sticky !important;
}
</style>
