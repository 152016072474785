<template>
  <div class="collection-preview">
    <div class="img-list">
      <div class="dots">
        <div
          v-for="(item, index) in imgList"
          :key="index"
          :a="index"
          :b="curIndex"
          :class="['dot', index === curIndex ? 'active' : '']"
        ></div>
      </div>
      <ul class="img-container" ref="imgContainer">
        <li
          v-for="(item1, index) in imgList"
          :key="index"
          :id="item1.code"
          :class="[imgIndex === index ? 'active' : '']"
          :style="{
            zIndex: `${positionList[index].zIndex}`,
            transform: `translateY(${positionList[index].translateY}px) scale(${positionList[index].scale})`,
            top: imgIndex === index ? elTop + 'px' : '0px',
          }"
          @touchstart="(e) => onTouchStart(e, index)"
          @touchmove="onTouchmove"
          @touchend="onTouchend"
          @click="toSubjectList(item1)"
        >
          <!-- <img crossorigin="anonymous" alt=""> -->
          <!-- <img :src="'data:image/png;base64,' + item1.image" /> -->
          <img :src="item1.poster" />
          <div class="title-masker">
            {{ item1.type_name }}
          </div>
        </li>

        <!-- <li v-if="imgList.length === 0">暂无数据</li> -->
      </ul>
    </div>
    <van-button
      type="info"
      round
      v-if="imgList.length > 0"
      @click="createPoster(imgIndex)"
      class="poster-btn"
      >生成海报</van-button
    >
    <div id="poster"></div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
export default {
  name: "BannerPreview",
  props: {
    // 图片数据，数组的值直接放图片链接就行了
    imgData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      // 当前展示的图片
      imgUrl: "",
      // 图片列表数据
      imgList: [],
      // 图片位置数据
      positionList: [],
      // 当前选中的图片下标
      imgIndex: 0,
      // 滑动初始top位置
      touchTop: 0,
      // 元素最终top位置
      elTop: 0,
      // 最大拖拽值
      touchMax: 50,
      curIndex: 0,
    };
  },
  watch: {
    // 监听图片数据
    imgData: {
      immediate: true, // 及时监听
      handler(newValue, oldValue) {
        if (newValue === oldValue || !newValue) {
          return;
        }
        this.imgList = [...newValue];
        this.setPositionList();
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    toSubjectList(item) {
      this.$emit("toList", item);
    },
    /**
     * 功能函数 *
     */
    // 设置定位值
    setPositionList() {
      this.positionList = this.imgList.map((el, index) => {
        // 图片缩放系数，以1为初始值
        let scale = 1 - 0.1 * index;
        // 图片向上偏移系数
        let top = 30;
        return {
          scale,
          zIndex: 100 - index,
          translateY: (top * index * scale) / 2,
        };
      });
    },
    // 上一个
    onPrev() {
      let pop = this.positionList.shift();
      this.positionList.push(pop);
      if (this.curIndex - 1 < 0) {
        this.curIndex = this.imgList.length - 1;
      } else {
        this.curIndex -= 1;
      }
    },
    // 下一个
    onNext() {
      let pop = this.positionList.pop();
      this.positionList.unshift(pop);
      if (this.curIndex + 1 >= this.imgList.length) {
        this.curIndex = 0;
      } else {
        this.curIndex += 1;
      }
    },
    // 拖拽开始
    onTouchStart(e, index) {
      // 目标事件
      let touch = e.targetTouches[0];
      // 目标元素
      let el = e.target;
      this.imgIndex = index;
      // 记录目标元素原始位置
      this.touchTop = touch.clientY - el.offsetTop;
      document.addEventListener("touchmove", this.defaultEvent, false);
    },
    // 拖拽移动
    onTouchmove(e) {
      // 目标事件
      let touch = e.targetTouches[0];
      let oTop = touch.clientY - this.touchTop;

      if (oTop < -this.touchMax) {
        oTop = -this.touchMax;
      } else if (oTop >= this.touchMax) {
        oTop = this.touchMax;
      }

      // 减小一倍拖动阻力
      this.elTop = oTop * 4;

      document.addEventListener("touchmove", this.defaultEvent, {
        passive: false,
      });
    },
    // 拖拽移动事件移除
    onTouchend() {
      if (this.elTop < -this.touchMax) {
        this.onPrev();
      } else if (this.elTop > this.touchMax) {
        this.onNext();
      }
      this.elTop = 0;
      document.removeEventListener("touchmove", this.defaultEvent);
    },
    //阻止默认事件
    defaultEvent(e) {
      // e.preventDefault();
    },

    async createPoster(idx) {
      const vm = this;
      const code = this.imgList[this.curIndex].code;
      const url = this.imgList[this.curIndex].poster;
      const domObj = await document.getElementById(code);
      html2canvas(domObj, {
        useCORS: true,
        allowTaint: false,
        logging: false,
        letterRendering: true,
        onclone(doc) {
          let e = doc.querySelector("#poster");
          e.style.display = "block";
        },
      }).then((canvas) => {
        let mata = "image/png";
        if (url.endsWith("png")) {
          vm.posterImg = canvas.toDataURL("image/png");
          console.log("png");
        } else {
          vm.posterImg = canvas.toDataURL("image/jpeg");
          console.log("jpg");
        }
        vm.imgDown(vm.posterImg, code);
      });
    },
    // 保存图片到本地
    imgDown(img, name) {
      var alink = document.createElement("a");
      alink.href = img;
      alink.download = name; // 图片名
      alink.click();
    },
  },
};
</script>
<style scoped>
.collection-preview {
  width: 100%;
  height: calc(100vh - 50px);
  background-image: url(../assets/images/body_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.collection-preview .img-list {
  position: relative;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.collection-preview .img-list .left-prev,
.collection-preview .img-list .right-next {
  position: absolute;
  top: 50%;
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 40px;
  cursor: pointer;
  color: #ddd;
  z-index: 1000;
}

.collection-preview .img-list .left-prev:hover,
.collection-preview .img-list .left-prev.active,
.collection-preview .img-list .right-next:hover,
.collection-preview .img-list .right-next.active {
  color: #999;
}

.collection-preview .img-list .left-prev {
  left: 0;
}

.collection-preview .img-list .right-next {
  right: 0;
}

.collection-preview .img-list ul.img-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.collection-preview .img-list ul li {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform-origin: center top;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  transform-origin: bottom center;
}

.collection-preview .img-list ul li.active {
  /* box-shadow: 1px 1px 10px #999; */
}

.collection-preview .img-list ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title-masker {
  width: 100%;
  position: absolute;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  bottom: 0;
  padding: 16px;
  border-radius: 0 0 20px 20px;
  color: #ffffff;
  font-size: 18px;
}

.poster-btn {
}
</style>
