<template>
  <div id="app">
    <Header v-if="$route.meta.showHeader" />
    <!-- <keep-alive><router-view /></keep-alive> -->
    <router-view />
    <van-tabbar route active-color="#1989fa" v-if="$route.meta.showTab">
      <van-tabbar-item icon="home-o" to="/home">首页</van-tabbar-item>
      <van-tabbar-item icon="like-o" to="/ztlist">{{
        $store.state.navTitle
      }}</van-tabbar-item>
      <!-- <van-tabbar-item icon="envelop-o" to="/mine">我的</van-tabbar-item> -->
    </van-tabbar>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
export default {
  name: "App",
  components: {
    Header,
  },
  data() {
    return {
      title: this.$store.state.navTitle,
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        this.$store.commit("updateTitle", {
          pushed: this.$route.name != "ztlist",
        });
        var title = "";
        if (this.$route.name == "ztlist" || this.$route.name == "Home") {
          title = this.$store.state.homeTitle;
        } else {
          if (this.$route.name == "List") {
            title = this.$route.query.name;
          } else {
            title = this.$store.state.navTitle;
          }
        }
        this.$store.commit("updateNavTitle", {
          navTitle: title,
        });
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.$store.dispatch("navTitleAction");
  },
  methods: {},
};
</script>
