import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";

Vue.config.productionTip = false;

import { VueJsonp } from "vue-jsonp";
Vue.use(VueJsonp);

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import "/src/assets/css/style.css";
// 全局挂载
Vue.use(VueAwesomeSwiper);

// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";

// Vue.use(Loading, {});
import Toast from "vue-easy-toast";
Vue.use(Toast, {
  duration: 1500,
  horizontalPosition: "center",
  verticalPosition: "top",
});

import Tools from "./utils/tools";
Vue.use({
  install(Vue, options) {
    Vue.prototype.decodeContent = Tools.decodeContent;
    Vue.prototype.urlToBase64 = Tools.urlToBase64;
    // Vue.prototype.calcMaxPage = Tools.calcMaxPage;
    // Vue.prototype.index2Chinese = Tools.indexToChinese;
    // Vue.prototype.formatDuration = Tools.formatDuration;
    // Vue.prototype.compareDate = Tools.compareDate;
  },
});

Vue.prototype.$eventBus = new Vue();

import {
  List,
  PullRefresh,
  Button,
  Loading,
  NavBar,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  Search,
  Cell,
} from "vant";
Vue.use(List)
  .use(PullRefresh)
  .use(Button)
  .use(Loading)
  .use(NavBar)
  .use(Swipe)
  .use(SwipeItem)
  .use(Tabbar)
  .use(Search)
  .use(TabbarItem)
  .use(Cell);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
